import React, { FC } from 'react'
import Chapter from '../../Layout/Chapter'
import ThankYouImage from '../../Assets/Images/thankYouImage.png'
import { useClearLocalStorageAfterLoad } from '../../hooks'
import Feedback from '../../components/Feedback'
import './thankyou.scss'
import { INFO_STRING_DELIMITER, InspectionReason, InspectorRole, LocalStorageKeys } from '../../constants'
import { useTranslation } from 'react-i18next'
import FlowStepsList from '../../components/FlowStepsList'

const ThankYou: FC = () => {
  const { t } = useTranslation()
  const inspectionData = JSON.parse(localStorage.getItem(LocalStorageKeys.InspectionData))
  useClearLocalStorageAfterLoad()

  const nextSteps = t('post-inspection-steps').split(INFO_STRING_DELIMITER)

  const hasStepsList = inspectionData?.inspectionReason === InspectionReason.END_LEASE_CONTRACT
    && inspectionData?.inspectorRole === InspectorRole.CUSTOMER

  return (
    <article className="thank-you">
      <Chapter
        image={ThankYouImage}
        title="inspection-completed"
        description={inspectionData?.inspectorRole === InspectorRole.CUSTOMER ? t('thank-you-page-description') : null}
        hasImagePadding={false}
      />
      { hasStepsList
        ? <div className="thank-you__steps-list">
          <FlowStepsList flowStepsList={nextSteps} title={t('what-happen-next')}/>
        </div>
        : inspectionData?.inspectorRole === InspectorRole.CUSTOMER ? <Feedback /> : null
      }
    </article>
  )
}

export default ThankYou