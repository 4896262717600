import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { leaseIdSelector, inspectionIdSelector, inspectionReasonState, inspectionStatusState,
  uuidState, inspectionAspectsSelector, themeState, flowStepsState, availableLanguagesSelector,
} from '../../Recoil'
import {
  InspectionReason,
  LocalStorageKeys, ROUTES, Theme, THEME_ITEM_KEY, UUID_DELIMITER,
} from '../../constants'
import { useTranslation } from 'react-i18next'
import { getInspectionData } from '../../api/apis'
import { getInspectionFlow, flows, languageChecker, FlowsExcludedFromInspectionReason } from '../../helpers'
import { isClientSigningFlowState } from '../../Recoil'
import i18n from 'i18next'
import './login.scss'
import LANGUAGE_COOKIE from '../../constants/languageCookieName'
import { InspectionStatusConfig } from '../../constants/inspectionStatus'

export const HAS_DEFAULT_THEME = Theme.ARVAL

// FIXME route welcome would trigger this component to be rerendered, which
// causes the navigation twice and unnecessary computing
const Login = () => {
  const { t } = useTranslation()
  const [query] = useSearchParams()
  const inspectionIdFromQuery = query.get(LocalStorageKeys.InspectionId)
  const isClientSigningFlow = query.get(LocalStorageKeys.CUSTOMER_SIGNING)
  const setCustomerSigningFlow = useSetRecoilState(isClientSigningFlowState)
  const setUuid = useSetRecoilState(uuidState)
  const [flow, setFlow] = useRecoilState(flowStepsState)
  const inspectionId = useRecoilValue(inspectionIdSelector)
  const navigate = useNavigate()
  const leaseId = useRecoilValue(leaseIdSelector)
  const availableLanguages = useRecoilValue(availableLanguagesSelector)
  const requiredAspects = useRecoilValue(inspectionAspectsSelector)
  const inspectionReason = useRecoilValue(inspectionReasonState)
  const inspectionStatus = useRecoilValue(inspectionStatusState)
  const setTheme = useSetRecoilState(themeState)

  useEffect(() => {
    if (inspectionIdFromQuery) {
      if (inspectionIdFromQuery.includes(UUID_DELIMITER)) {
        setUuid(inspectionIdFromQuery)
        localStorage.setItem(LocalStorageKeys.RequiredAspects, JSON.stringify(requiredAspects))
        if (isClientSigningFlow === 'true') {
          setFlow(flows[FlowsExcludedFromInspectionReason.SignInspectionReportFlow])
        } else if (inspectionReason === InspectionReason.END_LEASE_CONTRACT
          && inspectionStatus === InspectionStatusConfig.EXECUTE_INSPECTION) {
          setFlow(flows[FlowsExcludedFromInspectionReason.EndLeaseExtendedFlow])
        } else {
          setFlow(getInspectionFlow(inspectionReason))
        }
      }
    }
  }, [inspectionIdFromQuery])

  useEffect(() => {
    const inspectionData = localStorage.getItem(LocalStorageKeys.InspectionData)
    if (inspectionId && !inspectionData) {
      getInspectionData(inspectionId, availableLanguages)
    }
  }, [inspectionId])

  useEffect(() => {
    setCustomerSigningFlow(isClientSigningFlow)

    const queryString = query.toString()
    if (queryString.includes(Theme.JL)) {
      localStorage.setItem(THEME_ITEM_KEY, Theme.JL)
    } else {
      localStorage.setItem(THEME_ITEM_KEY, HAS_DEFAULT_THEME)
    }
    if (queryString.includes(LocalStorageKeys.RESET)) {
      localStorage.setItem(LocalStorageKeys.RESET, 'true')
    }
    const themeValue = localStorage.getItem(THEME_ITEM_KEY)
    if (themeValue !== HAS_DEFAULT_THEME) {
      setTheme(themeValue)
    }

    localStorage.setItem(LANGUAGE_COOKIE, languageChecker(i18n.language.split('-')[0]))
  }, [])

  useEffect(() => {
    if (leaseId && flow?.length) {
      navigate(flow[0].path)
    }
  }, [leaseId, inspectionId, flow])

  const handleClickReLogin  = () => {
    if (!inspectionIdFromQuery && !inspectionId) {
      alert(t('you-need-to-have-inspection-id-in-the-query-parameter'))
    } else {
      navigate(`/${ROUTES.PUBLIC_PATH.LOGIN}?inspectionid=${inspectionId}`, { replace: true })
    }
  }

  return (
    <div className="login">
      <div>Login</div>
      <button className="reload-btn" onClick={handleClickReLogin}>{t('log-back-in')}</button>
    </div>
  )
}

export default Login