import { atom, selector } from 'recoil'
import { inspectionIdSelector } from '../Selectors/inspectionIdSelector'
import { getSubmissionStatus } from '../../api/apis'

export const hasShortInspectionCompletedState = atom<boolean>({
  key: 'hasShortInspectionCompletedState',
  default:selector({
    key:'hasShortInspectionCompletedState/default',
    get: async({ get }) => {
      const inspectionId = get(inspectionIdSelector)
      const response = await getSubmissionStatus(+inspectionId)
      // XXX BE returns error as indication that this inspection has been submitted
      return response?.data?.error?.length > 0
    },
  }),
})