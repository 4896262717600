import { selector } from 'recoil'
import { getOptionsList } from '../../api/apis'
import { damageCategoryCode, keyOptionList } from '../../constants'
import { inspectionIdSelector } from './inspectionIdSelector'
import { availableLanguagesSelector } from './availableLanguagesSelector'

export const inspectionAspectsSelector = selector({
  key: 'inspectionAspectsSelector',
  get: async({ get }) => {
    const keyValue = keyOptionList.CATEGORY
    const value = damageCategoryCode.REQUIRED_IMAGE
    const inspectionId:string = get(inspectionIdSelector)
    const options = await getOptionsList(
      keyValue,
      value,
      get(availableLanguagesSelector),
      inspectionId,
    ) ?? []
    return options.map((option) => option.code)
  },
})