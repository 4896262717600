import React from 'react'
import './Loading.scss'
import ArvalLoader from '../../Assets/svg/ArvalLoader.svg'
import ArvalLoaderWhite from '../../Assets/svg/ArvalLoaderWhite.svg'
import { useRecoilValue } from 'recoil'
import { themeState } from '../../Recoil'
import { Theme } from '../../constants'
import { HAS_DEFAULT_THEME } from '../../Pages/Login'
import { loaderColor } from '../../constants'
interface LoadingProps {
  loadColor?: string,
}
const Loading = ({ loadColor }: LoadingProps) => {
  const theme = useRecoilValue(themeState)

  return (
    <div className="loading">
      { theme === HAS_DEFAULT_THEME &&
      <img src={loadColor === loaderColor.white ? ArvalLoaderWhite : ArvalLoader } alt=""
        className={loadColor === loaderColor.white ? `loading__arval-loader-${loadColor}` : 'loading__arval-loader'}/>
      }
      { theme === Theme.JL &&
        <div className={loadColor === loaderColor.white ? `loading__jl-loader-${loadColor}` : 'loading__jl-loader'}/>
      }
    </div>
  )
}

export default Loading