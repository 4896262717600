import { useNavigate, useLocation } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { flowStepsState } from '../Recoil'
import { getNextStepPath } from '../helpers'

const useNextStepNavigation = (): (() => void) => {
  const navigate = useNavigate()
  const location = useLocation()
  const flowSteps = useRecoilValue(flowStepsState)
  const nextStep = () => {
    const currentPath = location.pathname
    const stepName = getNextStepPath(flowSteps, currentPath)

    if (stepName?.path) {
      navigate(stepName.path)
    }
  }

  return nextStep
}

export default useNextStepNavigation