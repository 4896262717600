import { selector } from 'recoil'
import * as Sentry from '@sentry/react'
import { getOptionsList } from '../../api/apis'
import { v4 as uuidv4 } from 'uuid'
import { damageCategoryCode, keyOptionList } from '../../constants'
import { CategoryType } from './detailsListSelector'
import { SectionCodes } from '../../constants/optionConstantsList'
import { availableLanguagesSelector } from './availableLanguagesSelector'

export const partsListSelector = selector<CategoryType[]>({
  key: 'partsListSelector',
  get: async({ get }) => {
    try {
      const keyValue = keyOptionList.CATEGORY
      const value = damageCategoryCode.DAMAGE_PART
      const optionList = await getOptionsList(
        keyValue,
        value,
        get(availableLanguagesSelector),
      ) ?? []

      return optionList.reduce(
        (acc, item) => {
          if (item.section !== SectionCodes.SHORTAGE) {
            acc.push(({ id: uuidv4(), code: item.code, displayText: item.label }))
          }
          return acc
        }, [])
    } catch (err) {
      Sentry.captureException(err)
    }
  },
})