import { atom } from 'recoil'
import { LocalStorageKeys, ROUTES, UUID_DELIMITER } from '../../constants'
import * as Sentry from '@sentry/react'

export const uuidState = atom<string>({
  key: 'uuidState',
  default: '',
  effects: [
    ({ setSelf }) => {
      try {
        if (window.location.pathname.includes(ROUTES.PUBLIC_PATH.LOGIN)) {
          const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop as string),
          })
          if (params && params[LocalStorageKeys.InspectionId].includes(UUID_DELIMITER)) {
            const uuid = (params[LocalStorageKeys.InspectionId])
            setSelf(uuid)
            localStorage.setItem(LocalStorageKeys.UUID, (uuid))
          }
        } else {
          const uuid = localStorage.getItem(LocalStorageKeys.UUID)
          if (uuid) {
            setSelf(uuid)
          }
        }
      } catch (err) {
        Sentry.captureException(err)
      }
    },
    ({ onSet }) => {
      onSet((id) => {
        if (!id) {
          localStorage.removeItem(LocalStorageKeys.UUID)
        } else {
          localStorage.setItem(LocalStorageKeys.UUID, id)
        }
      })
    },
  ],
})