//TODO remove this enum when we implement separate domain for each CI
enum Theme {
  JL = 'jl-theme',
  ARVAL = 'arval-theme',
}

enum ThemeTitle {
  JL = 'JustLease',
  ARVAL = 'Arval',
}

const CIs = {
  JL: 'jl',
  ARVAL: 'arval',
}

export const THEME_ITEM_KEY = 'theme'
export { Theme, ThemeTitle, CIs }