import { atom } from 'recoil'
import { flows, FlowStepType, getInspectionFlow } from '../../helpers'
import { InspectionReason, LocalStorageKeys } from '../../constants'

export const flowStepsState = atom<FlowStepType[]>({
  key: 'flowStepsState',
  default: flows[InspectionReason.START_RENT_CONTRACT],
  effects: [
    ({ setSelf }) => {
      const inspectionReason = JSON.parse(localStorage.getItem(LocalStorageKeys.InspectionData))?.inspectionReason
      setSelf(getInspectionFlow(inspectionReason))
    },
  ],
})
