import { atom } from 'recoil'
import LocalStorageKeys from '../../constants/localStorageKeys'


export const isClientSigningFlowState = atom<string | null>({
  key: 'isClientSigningFlowState',
  default: localStorage.getItem(LocalStorageKeys.CUSTOMER_SIGNING),
  effects: [
    ({ onSet }) => {
      onSet((newValue) => {
        if (newValue) {
          localStorage.setItem(LocalStorageKeys.CUSTOMER_SIGNING, newValue)
        } else {
          localStorage.removeItem(LocalStorageKeys.CUSTOMER_SIGNING)
        }
      })
    }],
})