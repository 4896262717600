import { getStepsByFlowType } from './getFlow'
import { LocalStorageKeys } from '../constants'

//TODO: STOP using nextFlowStep. use getNextStepConfig instead
export const nextFlowStep = (location: string) => {
  const inspectionReason = localStorage.getItem(LocalStorageKeys.InspectionData)
  const isClientSigningFlow = localStorage.getItem(LocalStorageKeys.CUSTOMER_SIGNING)
  const flowStep = getStepsByFlowType(inspectionReason, isClientSigningFlow)
  const index = flowStep.indexOf(location)
  if (index < 0 || index === flowStep.length - 1) {
    return undefined
  }

  return flowStep[index + 1]
}

export const getNextStepPath = (flowSteps, location) => {
  const paths = flowSteps?.map(flow => flow.path)
  const index = paths?.indexOf(location)
  if (index < 0 || index === flowSteps.length - 1) {
    return undefined
  }

  return flowSteps[index + 1]
}