import { atom } from 'recoil'
import { getLocations } from '../../api/apis'
import * as Sentry from '@sentry/react'

export const locationListState = atom({
  key: 'locationListState',
  default: [],
  effects: [
    ({ setSelf }) => {
      try {
        setSelf(getLocations().then((res) => {
          return res.data?.locations
        }))
      } catch (err) {
        Sentry.captureException(err)
      }
    },
  ],
})