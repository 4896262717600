import { Col, Progress, Row } from 'antd'
import { FC, useMemo, useState } from 'react'
import { FaArrowLeft, FaGlobe } from 'react-icons/fa'
import { useLocation, useNavigate } from 'react-router-dom'
import { CHAPTER_ROUTES, ROUTES } from '../../constants'
import { NoStepBackPages } from '../../constants'
import { flows } from '../../helpers'
import { useRecoilValue } from 'recoil'
import { flowStepsState } from '../../Recoil'
import LanguageSelectorDrawer from '../../components/LanguageSelectorDrawer'
import styleVars from '../../styles/_branding-config.scss'
import './header.scss'

const Header: FC = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [showLanguageSelector, setShowLanguageSelector] = useState<boolean>(false)
  const allFlowSteps = useRecoilValue(flowStepsState)
  const locationPath = pathname.substring(1)
  const hasProgressBar = useMemo(
    () => ROUTES.ROUTES_CONFIG.find(
      route => route.path === locationPath
        || route.path === pathname.split('/')[1],
    )?.hasProgressBar,
    [locationPath])

  const isOnLandingPage = (path) => Object.keys(flows).find(flowType =>
    flows[flowType]?.[0]?.path === path,
  )
  const isLandingPage = useMemo(
    () => isOnLandingPage(pathname),
    [pathname],
  )

  const handleBackButtonClick = () => {
    setTimeout(() => { navigate(-1) }, 1000)
  }
  const handleLanguageSelectorOpen = () => {
    setShowLanguageSelector(true)
  }
  const handleLanguageSelectorClose = () => {
    setShowLanguageSelector(false)
  }

  const BackButton = () => (
    <Col span={3} className="header__left">
      { !NoStepBackPages.find(step => step === locationPath) &&
        <FaArrowLeft onClick={handleBackButtonClick} />
      }
    </Col>
  )
  const LanguageSelector = () => (
    <Col span={3} className="header__right">
      { isLandingPage && <FaGlobe onClick={handleLanguageSelectorOpen} /> }
    </Col>
  )

  const progressBarHeight = styleVars.progressBarHeight.split('px')[0]
  const isChapterRoute = CHAPTER_ROUTES.includes(locationPath)

  const pageTitle = useMemo(() => ROUTES.ROUTES_CONFIG.find(route =>
    route.path === locationPath || route.path === pathname.split('/')[1]
      || route.path === pathname.split('/')[1] + '/:id')?.title,
  [locationPath])

  const progressBarPercentage = useMemo(() => allFlowSteps
    ? allFlowSteps?.findIndex(step =>
      step.type === locationPath.split('/')[0]
      || step.type === locationPath) * 100 / (allFlowSteps.length - 2)
    : 0, [locationPath])

  const headerClassName = () => {
    const HEADER_DEFAULT_CLASS_NAME = 'header'

    return isChapterRoute
      ? `${HEADER_DEFAULT_CLASS_NAME} ${HEADER_DEFAULT_CLASS_NAME}--chapter`
      : HEADER_DEFAULT_CLASS_NAME
  }

  return (
    <header className={headerClassName()}>
      <Row justify="space-between" align="middle" className="header__row">
        {BackButton()}
        <Col span={18} className="header__title">
          {pageTitle}
        </Col>
        {LanguageSelector()}
      </Row>
      { hasProgressBar &&
        <Progress
          className="header__progress"
          percent={progressBarPercentage}
          strokeWidth={+progressBarHeight}
          strokeColor={styleVars.progressBarColor}
          showInfo={false}
        />
      }
      <LanguageSelectorDrawer
        isOpen={showLanguageSelector}
        onClose={handleLanguageSelectorClose}
      />
    </header>
  )
}

export default Header
