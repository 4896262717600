import React, { FC } from 'react'
import { ROUTES } from '../../constants'
import TbButton from '../../components/TbButton'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { accessoryChecks, inspectionIdSelector } from '../../Recoil'
import { getAccessories } from '../../api/apis'
import InfoImageAccessories from '../../Assets/Images/instructions/InfoImageAccessories.jpg'
import './accessories.scss'

const Accessories: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const inspectionId = useRecoilValue(inspectionIdSelector)
  const setClientsAccessories = useSetRecoilState(accessoryChecks)

  const handleClickContinue = async() => {
    const res = await getAccessories(inspectionId)
    setClientsAccessories(res)
    navigate(`/${ROUTES.PRIVATE_PATH.ACCESSORY_CHECK}`)
  }

  return (
    <div className="accessories">
      <p>{ t('check-accessories-explanation') }</p>
      <p>{ t('info-accessories-explanation') }</p>
      <div className="accessories__image-holder">
        <img src={InfoImageAccessories} alt="" className="accessories__image"></img>
      </div>
      <TbButton
        type="primary"
        onClick={handleClickContinue}>
        {t('continue-button-text')}
      </TbButton>
    </div>
  )
}

export default Accessories