import { LocalStorageKeys, InspectorRole, ROUTES, InspectionReason } from '../constants'
import { FlowsExcludedFromInspectionReason, flows, FlowStepType } from './flows'
import { externalAspects } from '../constants/videoCameraConstants'
import { memoize } from 'lodash-es'
import { InspectionStatusConfig } from '../constants/inspectionStatus'

export const getInspectionFlow = memoize((reason: string | number, isClientSigningFlow?: string) => {
  const inspectionData = JSON.parse(localStorage.getItem(LocalStorageKeys.InspectionData))
  if (isClientSigningFlow === 'true') {
    return flows[FlowsExcludedFromInspectionReason.SignInspectionReportFlow]
  }
  if (reason === InspectionReason.START_RENT_CONTRACT && inspectionData?.inspectorRole === InspectorRole.CUSTOMER) {
    return flows[FlowsExcludedFromInspectionReason.StartRentB2CFlow]
  }

  let initialFlow: FlowStepType[]
  if (inspectionData?.inspectionReason === InspectionReason.END_LEASE_CONTRACT
    && inspectionData?.inspectionStatus === InspectionStatusConfig.EXECUTE_INSPECTION
    && inspectionData.inspectorRole === InspectorRole.INTERNAL_EMPLOYEE) {
    initialFlow = flows[FlowsExcludedFromInspectionReason.EndLeaseExtendedFlow]
  } else initialFlow = flows[inspectionData?.inspectionReason] || flows[InspectionReason.END_LEASE_CONTRACT]
  const flowSteps = initialFlow?.filter(step => step?.inspector?.includes(inspectionData?.inspectorRole))

  // --------- adding OverviewCheckerSteps -------------
  const requiredAspectsList = JSON.parse(localStorage.getItem(LocalStorageKeys.RequiredAspects))

  const requiredRouts = (element) => requiredAspectsList[0] === externalAspects.videoAspect
    ? `/${ROUTES.PRIVATE_PATH.VIDEO_CHECKER}`
    : `/${ROUTES.PRIVATE_PATH.OVERVIEW}/${element.toLowerCase()}`

  const requiredType = (element) => requiredAspectsList[0] === externalAspects.videoAspect
    ? `${ROUTES.PRIVATE_PATH.OVERVIEW}/${ROUTES.PRIVATE_PATH.VIDEO_CHECKER_STEP}`
    : `${ROUTES.PRIVATE_PATH.OVERVIEW}/${element.toLowerCase()}`

  const exteriorCheckerIndex = flowSteps.findIndex(el => el.type === ROUTES.PRIVATE_PATH.OVERVIEW)
  const addingExteriorCheckerSteps = requiredAspectsList?.map((el, index) => ({
    type: requiredType(el),
    path: requiredRouts(el),
    // XXX we put getStepDescription function into the first transformed route of exterior checker 
    // in order to show steps on Welcome page. It can be the last one of exterior checker. Just pick
    // one of the exterior checker routes
    ...(index === 0 && { getStepDescription: flowSteps[exteriorCheckerIndex]?.getStepDescription }),
  }))

  const finalFlow = addingExteriorCheckerSteps?.length
    ? flowSteps?.slice(0, exteriorCheckerIndex)
      .concat(addingExteriorCheckerSteps)
      .concat(flowSteps.slice(exteriorCheckerIndex + 1))
    : flowSteps?.filter(flowStep => flowStep.type !== ROUTES.PRIVATE_PATH.OVERVIEW)

  return finalFlow
})

export const getStepsByFlowType = (flow:string, isClientSigningFlow?:string) => getInspectionFlow(
  flow, isClientSigningFlow,
).map(stepObject => stepObject.path)