import { selector } from 'recoil'
import { getOptionsList } from '../../api/apis'
import * as Sentry from '@sentry/react'
import { keyOptionList } from '../../constants'
import { damageSectionCode } from '../../constants/optionConstantsList'
import { inspectionIdSelector } from './inspectionIdSelector'
import { availableLanguagesSelector } from './availableLanguagesSelector'

export const accessoryImagesSelector = selector({
  key: 'accessoryImagesSelector',
  get: async({ get }) => {
    const inspectionId = get(inspectionIdSelector)
    try {
      const keyValue = keyOptionList.SECTION
      const value = damageSectionCode.IMAGES_STORAGE
      return await getOptionsList(
        keyValue,
        value,
        get(availableLanguagesSelector),
        inspectionId,
      )
    } catch (err) {
      Sentry.captureException(err)
    }
  },
})