import React, { FC } from 'react'
import './welcomeInfo.scss'
import { Collapse } from 'antd'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'

interface WelcomeInfoProps {
  title: string,
  infoSteps: {
    id: number,
    title: string,
    description: string,
  }[]
}

const WelcomeInfo: FC<WelcomeInfoProps> = ({
  title,
  infoSteps,
}) => {
  const { Panel } = Collapse

  return (
    <div className="welcome-info">
      <div className="welcome-info__title">{ title }</div>
      { infoSteps.map(step => (
        <Collapse className="welcome-info__console"
          key={step.id}
          defaultActiveKey={['1']}
          expandIcon={({ isActive }) => isActive ? <FaChevronDown/> : <FaChevronUp/>}
        >
          <Panel header={`${step.id}. ${step.title}`} key={step.id} className="welcome-info__subtitle">
            <p>{step.description}</p>
          </Panel>
        </Collapse>
      ))}
    </div>
  )
}

export default WelcomeInfo