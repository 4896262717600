import { atom } from 'recoil'
import { Orientation } from '../../constants'

export const orientationState = atom<string>({
  key: 'orientationState',
  default: '',
  effects: [
    ({ setSelf }) => {
      if (window.innerWidth > window.innerHeight) {
        setSelf(Orientation.LANDSCAPE)
      } else {
        setSelf(Orientation.PORTRAIT)
      }
    },
  ],
})