import React from 'react'
import { useRecoilValue } from 'recoil'
import { FaArrowLeft, FaRegFrown } from 'react-icons/fa'
import { Col, Progress, Row } from 'antd'
import styleVars from '../../styles/_branding-config.scss'
import { flowStepsState } from '../../Recoil'
import { useLocation, useNavigate } from 'react-router-dom'
import '../../Layout/Header/header.scss'
import './errorFallback.scss'
import { useTranslation } from 'react-i18next'

const ErrorFallback = () => {
  const flow = useRecoilValue(flowStepsState)
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const currentLocation = flow.find(el => el.path === pathname)

  return (
    <div className="error-page">
      <header className="header">
        <Row justify="space-between" align="middle" className="header__row">
          <Col span={3} className="header__left">
            <FaArrowLeft onClick={() => {
              navigate(flow[flow.indexOf(currentLocation) - 1].path, { replace: true })
              return window.location.reload()
            }}/>
          </Col>
          <Col span={18} className="header__title">
            {t('error')}
          </Col>
          <div></div>
        </Row>
        <Progress
          className="header__progress"
          percent={100}
          strokeWidth={+styleVars.progressBarHeight.split('px')[0]}
          strokeColor={styleVars.errorProgressBarColor}
          showInfo={false}
        />
      </header>
      <div className="error">
        <FaRegFrown className="error__icon"/>
        <p>{t('error-fallback-text')}</p>
      </div>
    </div>
  )
}

export default ErrorFallback