import { ROUTES } from '../constants'
import { nextFlowStep } from './nextFlowStep'
import i18n from 'i18next'

interface NextStepButtonOutput {
  isLastStep: boolean,
  buttonType: 'primary' | 'secondary',
  buttonTitle: string
}
export const nextStepButton = (pathname: string): NextStepButtonOutput  => {
  if (nextFlowStep(pathname) === `/${ROUTES.PUBLIC_PATH.THANK_YOU}`) {
    return {
      isLastStep: true,
      buttonType: 'secondary',
      buttonTitle: i18n.t('submit-inspection'),
    }
  } else if (nextFlowStep(pathname) === `/${ROUTES.PRIVATE_PATH.SCAN_QR}`) {
    return {
      isLastStep: false,
      buttonType: 'secondary',
      buttonTitle: i18n.t('to-signing'),
    }
  } else {
    return {
      isLastStep: false,
      buttonType: 'primary',
      buttonTitle: i18n.t('continue-button-text'),
    }
  }
}
