import { useEffect } from 'react'
import LANGUAGE_COOKIE from '../constants/languageCookieName'
import { LocalStorageKeys } from '../constants'

const doNotDeleteList = [
  LANGUAGE_COOKIE,
  LocalStorageKeys.InspectionData,
  LocalStorageKeys.THEME,
]

const useClearLocalStorageAfterLoad = () => {
  useEffect(() => {
    const allKeys = Object.keys(localStorage)
    const toBeDeleted = allKeys.filter(value => !doNotDeleteList.includes(value))
    toBeDeleted.forEach(value => { localStorage.removeItem(value) })
  }, [])
}

export default useClearLocalStorageAfterLoad
