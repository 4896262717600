import i18n from '../utils/i18n'
import InfoDamage1 from '../Assets/Images/instructions/infoDamage1.png'
import InfoDamage2 from '../Assets/Images/instructions/infoDamage2.png'
import InfoVideo1 from '../Assets/Images/instructions/infoVideo1.png'
import InfoVideo2 from '../Assets/Images/instructions/infoVideo2.png'
import InfoImageOdometer from '../Assets/Images/instructions/InfoImageOdometer.jpg'

export const DamageInstructionDataConfig = [
  { text: i18n.t('damage-overview-instruction1'), image: InfoDamage1 },
  { text: i18n.t('damage-overview-instruction2'), image: InfoDamage2 },
]

export const VideoInstructionDataConfig = [
  { text: i18n.t('video-instruction1'), image: InfoVideo1 },
  { text: i18n.t('video-instruction2'), image: InfoVideo2 },
]

export const OdometerInstructionDataConfig = [{
  text: i18n.t('odo-meter-instruction'), image: InfoImageOdometer,
}]

export const cameraBalloonInfoConfig = (balloonTitle?: string, photoNumber?: string) => {
  return {
    DAMAGE_OVERVIEW: i18n.t('damage-overview-balloon-text'),
    DAMAGE_DETAIL: i18n.t('damage-detail-balloon-text'),
    TIRES_OVERVIEW: i18n.t('tire-overview-balloon-text'),
    ODO_METER: i18n.t('odo-meter-balloon-text'),
    LICENSE_PLATE: i18n.t('license-plate-balloon-text'),
    VIDEO: i18n.t('video-balloon-text'),
    PHOTO_CHECKER: i18n.t('photo-checker-balloon-text',
      { balloonTitle, photoNumber }),
  }
}