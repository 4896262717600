import { FC, useCallback, useEffect } from 'react'
import Chapter from '../../Layout/Chapter'
import coverImage from '../../Assets/Images/coverImage.png'
import { useTranslation } from 'react-i18next'
import TbButton from '../../components/TbButton'
import { InspectionReason, ROUTES, InspectionStatusConfig, LocalStorageKeys } from '../../constants'
import {
  fetchStepsDescriptionWithInspectionType,
  triggerUpdateAcceptation,
} from '../../helpers'
import WelcomeInfo from '../../components/WelcomeInfo'
import { useRecoilValue } from 'recoil'
import { flowStepsState, inspectionIdSelector, inspectionReasonTitleState, themeState } from '../../Recoil'
import FlowStepsList from '../../components/FlowStepsList'
import { isClientSigningFlowState } from '../../Recoil'
import changeReasonTitle from '../../helpers/changeReasonTitle'
import useNextStepNavigation from '../../hooks/useNextStepNavigation'
import './welcome.scss'
import { useNavigate } from 'react-router-dom'


const Welcome: FC = () => {
  const { t } = useTranslation()
  const theme = useRecoilValue(themeState)
  const inspectionReasonTitle = useRecoilValue(inspectionReasonTitleState)
  const isClientSigningFlow = useRecoilValue(isClientSigningFlowState)
  const inspectionId = useRecoilValue(inspectionIdSelector)
  const inspectionData = JSON.parse(localStorage?.getItem(LocalStorageKeys.InspectionData))
  const inspectorType = inspectionData?.inspectorRole
  const flowSteps = useRecoilValue(flowStepsState)
  const goToNextStep = useNextStepNavigation()
  const navigate = useNavigate()
  const reset = localStorage.getItem(LocalStorageKeys.RESET)

  changeReasonTitle(inspectionReasonTitle, theme)
  const flowWelcomeInfo = flowSteps?.find(step => step.type === ROUTES.PRIVATE_PATH.WELCOME)
  const welcomeInfo = flowWelcomeInfo?.welcomePageInfo

  useEffect(() => {
    if (isClientSigningFlow === 'true') {
      triggerUpdateAcceptation(+inspectionId, { isQrCodeScanned: true })
    }
    if (inspectionData.inspectionReason === InspectionReason.END_LEASE_CONTRACT
      && inspectionData.inspectionStatus === InspectionStatusConfig.EXECUTE_INSPECTION && !reset) {
      navigate(`/${ROUTES.PUBLIC_PATH.THANK_YOU}`, { replace: true })
    }
  }, [])

  const fetchSteps = useCallback(() => {
    return fetchStepsDescriptionWithInspectionType(
      { flow:flowSteps, inspectorType },
    )
  }, [])
  const stepsList = fetchSteps()

  return (
    <div className="welcome">
      <Chapter
        image={coverImage}
        title={flowWelcomeInfo?.title}
        description={t(flowWelcomeInfo?.description)}
        timeTitle={
          t('short-inspection-flow-indication_other', { count: flowWelcomeInfo?.passageTime })
        }
        hasRemainingTimeIndication={true}
        hasImagePadding={false}
      />
      { flowWelcomeInfo?.welcomePageInfo &&
        <WelcomeInfo title={welcomeInfo?.title}
          infoSteps={welcomeInfo?.infoSteps}/>
      }
      { stepsList?.length !== 0 &&
        <div className="welcome__flow-steps">
          <FlowStepsList flowStepsList={stepsList} title={t('title-flow-steps-list')}/>
        </div>
      }
      <div className="footer-button">
        <TbButton
          type="primary"
          htmlType="button"
          onClick={goToNextStep}
        >
          {t('continue-button-text')}
        </TbButton>
      </div>
    </div>
  )
}

export default Welcome