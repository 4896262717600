import {
  FlowTypeMap,
  InspectionReasonsToShowTiresPage,
  ROUTES,
  InspectorRole,
  LocalStorageKeys,
  InteriorCheckSteps,
  ExteriorCheckSteps,
} from '../constants'
import i18n from '../utils/i18n'
import { updateStatus } from '../api/apis'
import { flowWelcomeInfoSteps } from '../constants/flowInfoSteps'
import { externalAspects } from '../constants/videoCameraConstants'
import { SUMMARY } from '../constants/buildingBlocks'
import { FlowDescriptionKeys } from '../constants/flowDescriptionKeys'

type InfoStepsType = {
  id: number
  title: string
  description: string
}

type WelcomePageInfoType = {
  title: string
  infoSteps: InfoStepsType[]
}

type SuppressionCreteriasType = {
  inspectorType?: typeof InspectorRole[keyof typeof InspectorRole],
  inspectionReason?: typeof InspectionReasonsToShowTiresPage[number]
}

type ConditionType = null | ((arg: boolean) => boolean)

export type FlowStepType = {
  type: typeof ROUTES.PRIVATE_PATH[keyof typeof ROUTES.PRIVATE_PATH]
  | typeof ROUTES.PUBLIC_PATH[keyof typeof ROUTES.PUBLIC_PATH]
  path: string
  inspector?: number[]
  title?: string
  description?: string
  welcomePageInfo?: WelcomePageInfoType | null
  passageTime?: number
  getStepDescription?: (suppressionCreterias?: SuppressionCreteriasType) => string
  triggerUpdateStatus?: (inspectionId: number) => void
  buildingBlocks?: Record<string, ConditionType >
}

export const FlowsExcludedFromInspectionReason = {
  SignInspectionReportFlow: 'SignInspectionReportFlow',
  StartRentB2CFlow: 'StartRentB2CFlow',
  PuttingIntoUseFlow: 'PuttingIntoUseFlow',
  EndLeaseExtendedFlow: 'ExtendedInspection',
}

const ExteriorSteps = ExteriorCheckSteps?.map((el, i) => {
  return {
    type: `${ROUTES.PRIVATE_PATH.EXTERIOR}/${el}`,
    path: `/${ROUTES.PRIVATE_PATH.EXTERIOR}/${el}`,
    inspector: [InspectorRole.INTERNAL_EMPLOYEE],
    getStepDescription: () => i === 0 ? FlowDescriptionKeys.EXTERIOR_DESCRIPTION_KEY : null,
  }
})

const InteriorSteps = InteriorCheckSteps?.map((el, i) => {
  return {
    type: `${ROUTES.PRIVATE_PATH.INTERIOR}/${el}`,
    path: `/${ROUTES.PRIVATE_PATH.INTERIOR}/${el}`,
    inspector: [InspectorRole.INTERNAL_EMPLOYEE],
    getStepDescription: () => i === 0 ? FlowDescriptionKeys.INTERIOR_DESCRIPTION_KEY : null,
  }
})

const updateInspectionStatus = (inspectionId) => {
  if (inspectionId === undefined) {
    return
  }
  updateStatus({ inspectionId })
}

const getStepDescriptionForOverviewStep = () =>
  JSON.parse(
    localStorage.getItem(LocalStorageKeys.RequiredAspects),
  )?.includes(externalAspects.videoAspect)
    ? FlowDescriptionKeys.EXTERIOR_CHECKER_STEP_DESCRIPTION_KEY_CUSTOMER
    : FlowDescriptionKeys.EXTERIOR_CHECKER_STEP_DESCRIPTION_KEY


// FIXME Should use proper static type instead of using translation
// as type. Refactor following code when BE deliveries the flow type.
// type FlowType = keyof typeof FlowTypes => use FlowType as key in next type
type FlowObjectType = Record<string, FlowStepType[]>

export const flows: FlowObjectType = {
  [FlowTypeMap.EndLeaseContractFlow]: [
    {
      type: ROUTES.PRIVATE_PATH.WELCOME,
      path: `/${ROUTES.PRIVATE_PATH.WELCOME}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
        InspectorRole.CUSTOMER,
      ],
      title: 'welcome-page-title',
      description: 'short-inspection-explanation',
      welcomePageInfo: null,
      passageTime: 10,
    },
    {
      type: ROUTES.PRIVATE_PATH.VEHICLE_DETAILS,
      path: `/${ROUTES.PRIVATE_PATH.VEHICLE_DETAILS}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
        InspectorRole.CUSTOMER,
      ],
      getStepDescription: () => FlowDescriptionKeys.VEHICLE_DETAIL_STEP_DESCRIPTION_KEY,
    },
    {
      type: ROUTES.PRIVATE_PATH.ODO_METER,
      path: `/${ROUTES.PRIVATE_PATH.ODO_METER}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
        InspectorRole.CUSTOMER,
      ],
      getStepDescription: () => FlowDescriptionKeys.ODO_METER_STEP_DESCRIPTION_KEY,
    },
    {
      type: ROUTES.PRIVATE_PATH.OVERVIEW,
      path: `/${ROUTES.PRIVATE_PATH.OVERVIEW}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
        InspectorRole.CUSTOMER,
      ],
      getStepDescription: getStepDescriptionForOverviewStep,
    },
    {
      type: ROUTES.PRIVATE_PATH.DAMAGE_OVERVIEW,
      path: `/${ROUTES.PRIVATE_PATH.DAMAGE_OVERVIEW}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
        InspectorRole.CUSTOMER,
      ],
      getStepDescription: () => FlowDescriptionKeys.CHECK_DAMAGES_STEP_DESCRIPTION_KEY,
    },
    {
      type: ROUTES.PRIVATE_PATH.ACCESSORIES,
      path: `/${ROUTES.PRIVATE_PATH.ACCESSORIES}`,
      inspector: [
        InspectorRole.CUSTOMER,
      ],
    },
    {
      type: ROUTES.PRIVATE_PATH.ACCESSORY_CHECK,
      path: `/${ROUTES.PRIVATE_PATH.ACCESSORY_CHECK}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
        InspectorRole.CUSTOMER,
      ],
      getStepDescription: () => FlowDescriptionKeys.ACCESSORY_STEP_DESCRIPTION_KEY,
    },
    {
      type: ROUTES.PRIVATE_PATH.LOCATION,
      path: `/${ROUTES.PRIVATE_PATH.LOCATION}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
        InspectorRole.CUSTOMER,
      ],
      getStepDescription: () => FlowDescriptionKeys.LOCATION_STEP_DESCRIPTION_KEY,
      triggerUpdateStatus: updateInspectionStatus,
    },
    {
      type: ROUTES.PRIVATE_PATH.SUMMARY,
      path: `/${ROUTES.PRIVATE_PATH.SUMMARY}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
        InspectorRole.CUSTOMER,
      ],
      getStepDescription: () => FlowDescriptionKeys.SUMMARY_DESCRIPTION_KEY,
      triggerUpdateStatus: updateInspectionStatus,
    },
    {
      type: ROUTES.PUBLIC_PATH.THANK_YOU,
      path: `/${ROUTES.PUBLIC_PATH.THANK_YOU}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
        InspectorRole.CUSTOMER,
      ],
    },
  ],
  [FlowTypeMap.EndRentContractFlow]: [
    {
      type: ROUTES.PRIVATE_PATH.WELCOME,
      path: `/${ROUTES.PRIVATE_PATH.WELCOME}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
        InspectorRole.CUSTOMER,
      ],
      title: 'welcome-page-title',
      description: 'short-inspection-explanation',
      welcomePageInfo: null,
      passageTime: 10,
    },
    {
      type: ROUTES.PRIVATE_PATH.VEHICLE_DETAILS,
      path: `/${ROUTES.PRIVATE_PATH.VEHICLE_DETAILS}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
        InspectorRole.CUSTOMER,
      ],
      getStepDescription: () => FlowDescriptionKeys.VEHICLE_DETAIL_STEP_DESCRIPTION_KEY,
    },
    {
      type: ROUTES.PRIVATE_PATH.ODO_METER,
      path: `/${ROUTES.PRIVATE_PATH.ODO_METER}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
        InspectorRole.CUSTOMER,
      ],
      getStepDescription: () => FlowDescriptionKeys.ODO_METER_STEP_DESCRIPTION_KEY,
    },
    {
      type: ROUTES.PRIVATE_PATH.OVERVIEW,
      path: `/${ROUTES.PRIVATE_PATH.OVERVIEW}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
        InspectorRole.CUSTOMER,
      ],
      getStepDescription: getStepDescriptionForOverviewStep,
    },
    {
      type: ROUTES.PRIVATE_PATH.DAMAGE_OVERVIEW,
      path: `/${ROUTES.PRIVATE_PATH.DAMAGE_OVERVIEW}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
        InspectorRole.CUSTOMER,
      ],
      getStepDescription: () => FlowDescriptionKeys.CHECK_DAMAGES_STEP_DESCRIPTION_KEY,
    },
    {
      type: ROUTES.PRIVATE_PATH.TIRES_OVERVIEW,
      path: `/${ROUTES.PRIVATE_PATH.TIRES_OVERVIEW}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
      ],
      getStepDescription: () => FlowDescriptionKeys.CHECK_TIRES_STEP_DESCRIPTION_KEY,
    },
    {
      type: ROUTES.PRIVATE_PATH.ACCESSORIES,
      path: `/${ROUTES.PRIVATE_PATH.ACCESSORIES}`,
      inspector: [
        InspectorRole.CUSTOMER,
      ],
    },
    {
      type: ROUTES.PRIVATE_PATH.ACCESSORY_CHECK,
      path: `/${ROUTES.PRIVATE_PATH.ACCESSORY_CHECK}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
        InspectorRole.CUSTOMER,
      ],
      getStepDescription: () => FlowDescriptionKeys.ACCESSORY_STEP_DESCRIPTION_KEY,
    },
    {
      type: ROUTES.PRIVATE_PATH.LOCATION,
      path: `/${ROUTES.PRIVATE_PATH.LOCATION}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
        InspectorRole.CUSTOMER,
      ],
      getStepDescription: () => FlowDescriptionKeys.LOCATION_STEP_DESCRIPTION_KEY,
      triggerUpdateStatus: updateInspectionStatus,
    },
    {
      type: ROUTES.PRIVATE_PATH.SUMMARY,
      path: `/${ROUTES.PRIVATE_PATH.SUMMARY}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
        InspectorRole.CUSTOMER,
      ],
      getStepDescription: () => FlowDescriptionKeys.SUMMARY_DESCRIPTION_KEY,
      triggerUpdateStatus: updateInspectionStatus,
    },
    {
      type: ROUTES.PUBLIC_PATH.THANK_YOU,
      path: `/${ROUTES.PUBLIC_PATH.THANK_YOU}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
        InspectorRole.CUSTOMER,
      ],
    },
  ],
  [FlowTypeMap.StartLeaseContractFlow]: [
    {
      type: ROUTES.PRIVATE_PATH.WELCOME,
      path: `/${ROUTES.PRIVATE_PATH.WELCOME}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
        InspectorRole.CUSTOMER,
      ],
      title: 'welcome-page-title',
      description: 'short-inspection-explanation',
      welcomePageInfo: null,
      passageTime: 10,
    },
    {
      type: ROUTES.PRIVATE_PATH.VEHICLE_DETAILS,
      path: `/${ROUTES.PRIVATE_PATH.VEHICLE_DETAILS}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
        InspectorRole.CUSTOMER,
      ],
      getStepDescription: () => FlowDescriptionKeys.VEHICLE_DETAIL_STEP_DESCRIPTION_KEY,
    },
    {
      type: ROUTES.PRIVATE_PATH.ODO_METER,
      path: `/${ROUTES.PRIVATE_PATH.ODO_METER}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
        InspectorRole.CUSTOMER,
      ],
      getStepDescription: () => FlowDescriptionKeys.ODO_METER_STEP_DESCRIPTION_KEY,
    },
    {
      type: ROUTES.PRIVATE_PATH.OVERVIEW,
      path: `/${ROUTES.PRIVATE_PATH.OVERVIEW}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
        InspectorRole.CUSTOMER,
      ],
      getStepDescription: getStepDescriptionForOverviewStep,
    },
    {
      type: ROUTES.PRIVATE_PATH.DAMAGE_OVERVIEW,
      path: `/${ROUTES.PRIVATE_PATH.DAMAGE_OVERVIEW}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
        InspectorRole.CUSTOMER,
      ],
      getStepDescription: () => FlowDescriptionKeys.CHECK_DAMAGES_STEP_DESCRIPTION_KEY,
    },
    {
      type: ROUTES.PRIVATE_PATH.ACCESSORIES,
      path: `/${ROUTES.PRIVATE_PATH.ACCESSORIES}`,
      inspector: [
        InspectorRole.CUSTOMER,
      ],
    },
    {
      type: ROUTES.PRIVATE_PATH.ACCESSORY_CHECK,
      path: `/${ROUTES.PRIVATE_PATH.ACCESSORY_CHECK}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
        InspectorRole.CUSTOMER,
      ],
      getStepDescription: () => FlowDescriptionKeys.ACCESSORY_STEP_DESCRIPTION_KEY,
    },
    {
      type: ROUTES.PRIVATE_PATH.LOCATION,
      path: `/${ROUTES.PRIVATE_PATH.LOCATION}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
        InspectorRole.CUSTOMER,
      ],
      getStepDescription: () => FlowDescriptionKeys.LOCATION_STEP_DESCRIPTION_KEY,
      triggerUpdateStatus: updateInspectionStatus,
    },
    {
      type: ROUTES.PRIVATE_PATH.SCAN_QR,
      path: `/${ROUTES.PRIVATE_PATH.SCAN_QR}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
      ],
    },
    {
      type: ROUTES.PRIVATE_PATH.SUMMARY,
      path: `/${ROUTES.PRIVATE_PATH.SUMMARY}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
        InspectorRole.CUSTOMER,
      ],
      getStepDescription: () => FlowDescriptionKeys.SUMMARY_DESCRIPTION_KEY,
      triggerUpdateStatus: updateInspectionStatus,
      buildingBlocks:{
        [SUMMARY.ACCEPTATION]:null,
        [SUMMARY.EMAIL_ADDRESS]:null,
        [SUMMARY.FIRST_NAME]:null,
        [SUMMARY.LAST_NAME]:null,
        [SUMMARY.REMARK]: (shouldShow) => !shouldShow,
      },
    },
    {
      type: ROUTES.PUBLIC_PATH.THANK_YOU,
      path: `/${ROUTES.PUBLIC_PATH.THANK_YOU}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
        InspectorRole.CUSTOMER,
      ],
    },
  ],
  [FlowTypeMap.StartRentContractFlow]: [
    {
      type: ROUTES.PRIVATE_PATH.WELCOME,
      path: `/${ROUTES.PRIVATE_PATH.WELCOME}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
        InspectorRole.CUSTOMER,
      ],
      title: 'welcome-page-title',
      description: 'short-inspection-explanation',
      welcomePageInfo: null,
      passageTime: 10,
    },
    {
      type: ROUTES.PRIVATE_PATH.VEHICLE_DETAILS,
      path: `/${ROUTES.PRIVATE_PATH.VEHICLE_DETAILS}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
        InspectorRole.CUSTOMER,
      ],
      getStepDescription: () => FlowDescriptionKeys.VEHICLE_DETAIL_STEP_DESCRIPTION_KEY,
    },
    {
      type: ROUTES.PRIVATE_PATH.ODO_METER,
      path: `/${ROUTES.PRIVATE_PATH.ODO_METER}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
        InspectorRole.CUSTOMER,
      ],
      getStepDescription: () => FlowDescriptionKeys.ODO_METER_STEP_DESCRIPTION_KEY,
    },
    {
      type: ROUTES.PRIVATE_PATH.OVERVIEW,
      path: `/${ROUTES.PRIVATE_PATH.OVERVIEW}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
        InspectorRole.CUSTOMER,
      ],
      getStepDescription: getStepDescriptionForOverviewStep,
    },
    {
      type: ROUTES.PRIVATE_PATH.DAMAGE_OVERVIEW,
      path: `/${ROUTES.PRIVATE_PATH.DAMAGE_OVERVIEW}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
        InspectorRole.CUSTOMER,
      ],
      getStepDescription: () => FlowDescriptionKeys.CHECK_DAMAGES_STEP_DESCRIPTION_KEY,
    },
    {
      type: ROUTES.PRIVATE_PATH.TIRES_OVERVIEW,
      path: `/${ROUTES.PRIVATE_PATH.TIRES_OVERVIEW}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
      ],
      getStepDescription: () => FlowDescriptionKeys.CHECK_TIRES_STEP_DESCRIPTION_KEY,
    },
    {
      type: ROUTES.PRIVATE_PATH.ACCESSORIES,
      path: `/${ROUTES.PRIVATE_PATH.ACCESSORIES}`,
      inspector: [
        InspectorRole.CUSTOMER,
      ],
    },
    {
      type: ROUTES.PRIVATE_PATH.ACCESSORY_CHECK,
      path: `/${ROUTES.PRIVATE_PATH.ACCESSORY_CHECK}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
        InspectorRole.CUSTOMER,
      ],
      getStepDescription: () => FlowDescriptionKeys.ACCESSORY_STEP_DESCRIPTION_KEY,
    },
    {
      type: ROUTES.PRIVATE_PATH.LOCATION,
      path: `/${ROUTES.PRIVATE_PATH.LOCATION}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
        InspectorRole.CUSTOMER,
      ],
      getStepDescription: () => FlowDescriptionKeys.LOCATION_STEP_DESCRIPTION_KEY,
      triggerUpdateStatus: updateInspectionStatus,
    },
    {
      type: ROUTES.PRIVATE_PATH.SCAN_QR,
      path: `/${ROUTES.PRIVATE_PATH.SCAN_QR}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
      ],
    },
    {
      type: ROUTES.PRIVATE_PATH.SUMMARY,
      path: `/${ROUTES.PRIVATE_PATH.SUMMARY}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
        InspectorRole.CUSTOMER,
      ],
      getStepDescription: () => FlowDescriptionKeys.SUMMARY_DESCRIPTION_KEY,
      triggerUpdateStatus: updateInspectionStatus,
      buildingBlocks:{
        [SUMMARY.ACCEPTATION]: null,
        [SUMMARY.EMAIL_ADDRESS]: null,
        [SUMMARY.FIRST_NAME]: null,
        [SUMMARY.LAST_NAME]: null,
        [SUMMARY.REMARK]: (isAccepted) =>  !isAccepted,
      },
    },
    {
      type: ROUTES.PUBLIC_PATH.THANK_YOU,
      path: `/${ROUTES.PUBLIC_PATH.THANK_YOU}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
        InspectorRole.CUSTOMER,
      ],
    },
  ],
  [FlowsExcludedFromInspectionReason.SignInspectionReportFlow]: [
    {
      type: ROUTES.PRIVATE_PATH.WELCOME,
      path: `/${ROUTES.PRIVATE_PATH.WELCOME}`,
      inspector: [
        InspectorRole.CUSTOMER,
      ],
      description: 'sign-inspection-report-explanation',
      welcomePageInfo: null,
      passageTime: 2,
    },
    {
      type: ROUTES.PRIVATE_PATH.SUMMARY,
      path: `/${ROUTES.PRIVATE_PATH.SUMMARY}`,
      inspector: [
        InspectorRole.CUSTOMER,
      ],
      getStepDescription: () => FlowDescriptionKeys.SUMMARY_DESCRIPTION_KEY,
      triggerUpdateStatus: updateInspectionStatus,
      buildingBlocks:{
        [SUMMARY.ACCEPTATION]: null,
        [SUMMARY.EMAIL_ADDRESS]: null,
        [SUMMARY.FIRST_NAME]: null,
        [SUMMARY.LAST_NAME]: null,
        [SUMMARY.REMARK]: (isAccepted) =>  !isAccepted,
      },
    },
    {
      type: ROUTES.PUBLIC_PATH.THANK_YOU,
      path: `/${ROUTES.PUBLIC_PATH.THANK_YOU}`,
      inspector: [
        InspectorRole.CUSTOMER,
      ],
    },
  ],
  [FlowsExcludedFromInspectionReason.StartRentB2CFlow]: [
    {
      type: ROUTES.PRIVATE_PATH.WELCOME,
      title: 'welcome-page-title',
      path: `/${ROUTES.PRIVATE_PATH.WELCOME}`,
      description: 'start-rent-b2c-explanation',
      welcomePageInfo: null,
      passageTime: 5,
    },
    {
      type: ROUTES.PRIVATE_PATH.FIND_LICENSE_PLATE,
      path: `/${ROUTES.PRIVATE_PATH.FIND_LICENSE_PLATE}`,
    },
    {
      type: ROUTES.PRIVATE_PATH.VEHICLE_DETAILS,
      path: `/${ROUTES.PRIVATE_PATH.VEHICLE_DETAILS}`,
    },
    {
      type: ROUTES.PRIVATE_PATH.DAMAGE_OVERVIEW,
      path: `/${ROUTES.PRIVATE_PATH.DAMAGE_OVERVIEW}`,
    },
    {
      type: ROUTES.PRIVATE_PATH.SUMMARY,
      path: `/${ROUTES.PRIVATE_PATH.SUMMARY}`,
      triggerUpdateStatus: updateInspectionStatus,
      buildingBlocks:{
        [SUMMARY.EMAIL_ADDRESS]: null,
        [SUMMARY.FIRST_NAME]: null,
        [SUMMARY.LAST_NAME]: null,
        [SUMMARY.REMARK]: null,
      },
    },
    {
      type: ROUTES.PUBLIC_PATH.THANK_YOU,
      path: `/${ROUTES.PUBLIC_PATH.THANK_YOU}`,
    },
  ],
  [FlowsExcludedFromInspectionReason.PuttingIntoUseFlow]: [
    {
      type: ROUTES.PRIVATE_PATH.WELCOME,
      path: `/${ROUTES.PRIVATE_PATH.WELCOME}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
        InspectorRole.CUSTOMER,
      ],
      description: 'putting-into-use-explanation',
      title: 'welcome-page-title',
      welcomePageInfo: {
        title: i18n.t('putting-into-use-info-title'),
        infoSteps: flowWelcomeInfoSteps.puttingIntoUse,
      },
      passageTime: 5,
    },
    {
      type: ROUTES.PRIVATE_PATH.VEHICLE_DETAILS,
      path: `/${ROUTES.PRIVATE_PATH.VEHICLE_DETAILS}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
        InspectorRole.CUSTOMER,
      ],
      getStepDescription: () => FlowDescriptionKeys.VEHICLE_DETAIL_STEP_DESCRIPTION_KEY,
    },
    {
      type: ROUTES.PRIVATE_PATH.LOCATION,
      path: `/${ROUTES.PRIVATE_PATH.LOCATION}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
        InspectorRole.CUSTOMER,
      ],
      getStepDescription: () => FlowDescriptionKeys.LOCATION_STEP_DESCRIPTION_KEY,
    },
    {
      type: ROUTES.PRIVATE_PATH.ODO_METER,
      path: `/${ROUTES.PRIVATE_PATH.ODO_METER}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
        InspectorRole.CUSTOMER,
      ],
      getStepDescription: () => FlowDescriptionKeys.ODO_METER_STEP_DESCRIPTION_KEY,
    },
    {
      type: ROUTES.PRIVATE_PATH.DAMAGE_OVERVIEW,
      path: `/${ROUTES.PRIVATE_PATH.DAMAGE_OVERVIEW}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
        InspectorRole.CUSTOMER,
      ],
      getStepDescription: () => FlowDescriptionKeys.CHECK_DAMAGES_STEP_DESCRIPTION_KEY,
    },
    {
      type: ROUTES.PRIVATE_PATH.SUMMARY,
      path: `/${ROUTES.PRIVATE_PATH.SUMMARY}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
        InspectorRole.CUSTOMER,
      ],
      getStepDescription: () => FlowDescriptionKeys.SUMMARY_DESCRIPTION_KEY,
    },
    {
      type: ROUTES.PUBLIC_PATH.THANK_YOU,
      path: `/${ROUTES.PUBLIC_PATH.THANK_YOU}`,
      inspector: [
        InspectorRole.CUSTOMER,
      ],
    },
  ],
  [FlowsExcludedFromInspectionReason.EndLeaseExtendedFlow]: [
    {
      type: ROUTES.PRIVATE_PATH.WELCOME,
      path: `/${ROUTES.PRIVATE_PATH.WELCOME}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
      ],
      title: 'welcome-page-title',
      description: 'extended-inspection-explanation',
      welcomePageInfo: null,
      passageTime: 20,
    },
    {
      type: ROUTES.PRIVATE_PATH.VEHICLE_DETAILS,
      path: `/${ROUTES.PRIVATE_PATH.VEHICLE_DETAILS}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
      ],
      getStepDescription: () => FlowDescriptionKeys.VEHICLE_DETAIL_STEP_DESCRIPTION_KEY,
    },
    {
      type: ROUTES.PRIVATE_PATH.ODO_METER,
      path: `/${ROUTES.PRIVATE_PATH.ODO_METER}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
      ],
      getStepDescription: () => FlowDescriptionKeys.ODO_METER_STEP_DESCRIPTION_KEY,
    },
    ...InteriorSteps,
    {
      type: ROUTES.PRIVATE_PATH.OVERVIEW,
      path: `/${ROUTES.PRIVATE_PATH.OVERVIEW}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
      ],
      getStepDescription: getStepDescriptionForOverviewStep,
    },
    ...ExteriorSteps,
    {
      type: ROUTES.PRIVATE_PATH.DAMAGE_OVERVIEW,
      path: `/${ROUTES.PRIVATE_PATH.DAMAGE_OVERVIEW}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
      ],
      getStepDescription: () => FlowDescriptionKeys.CHECK_DAMAGES_STEP_DESCRIPTION_KEY,
    },
    {
      type: ROUTES.PRIVATE_PATH.TIRES_OVERVIEW,
      path: `/${ROUTES.PRIVATE_PATH.TIRES_OVERVIEW}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
      ],
      getStepDescription: () => FlowDescriptionKeys.CHECK_TIRES_STEP_DESCRIPTION_KEY,
    },
    {
      type: ROUTES.PRIVATE_PATH.ACCESSORY_CHECK,
      path: `/${ROUTES.PRIVATE_PATH.ACCESSORY_CHECK}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
      ],
      getStepDescription: () => FlowDescriptionKeys.ACCESSORY_STEP_DESCRIPTION_KEY,
    },
    {
      type: ROUTES.PRIVATE_PATH.LOCATION,
      path: `/${ROUTES.PRIVATE_PATH.LOCATION}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
      ],
      getStepDescription: () => FlowDescriptionKeys.LOCATION_STEP_DESCRIPTION_KEY,
    },
    {
      type: ROUTES.PRIVATE_PATH.SUMMARY,
      path: `/${ROUTES.PRIVATE_PATH.SUMMARY}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
      ],
      getStepDescription: () => FlowDescriptionKeys.SUMMARY_DESCRIPTION_KEY,
      triggerUpdateStatus: updateInspectionStatus,
    },
    {
      type: ROUTES.PUBLIC_PATH.THANK_YOU,
      path: `/${ROUTES.PUBLIC_PATH.THANK_YOU}`,
      inspector: [
        InspectorRole.INTERNAL_EMPLOYEE,
      ],
    },
  ],
}

export const fetchStepsDescriptionWithInspectionType = ({ flow, inspectorType }) => {
  if (flow?.length) {
    return flow.reduce((acc, step) => {
      if (
        (step.inspector === undefined
        || step.inspector.includes(inspectorType))
        && step.getStepDescription) {
        const key = step.getStepDescription({
          inspectorType,
        })
        if (key) {
          acc.push(i18n.t(key))
        }
      }
      return acc
    }, [])
  }
  return []
}

export const getCurrentPageConfig = ({ currentFlowSteps, pathName }) => {
  return currentFlowSteps.find((stepConfig) => stepConfig.path === pathName)
}