export const ExteriorCheckSteps = [
  'cargo',
  'luxury',
]

export const InteriorCheckSteps = [
  'safety',
  'climate',
  'media',
  'comfort',
  'security',
]