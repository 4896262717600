import { selector } from 'recoil'
import { getLanguages } from '../../api/apis'
import * as Sentry from '@sentry/react'
import { isAuthenticatedState } from '..'

export const availableLanguagesSelector = selector({
  key: 'availableLanguagesSelector',
  get: async({ get }) => {
    try {
      const isAuthenticated = get(isAuthenticatedState)
      if (isAuthenticated) {
        return await getLanguages()
      }
    } catch (err) {
      Sentry.captureException(err)
    }
  },
})