import { selector } from 'recoil'
import { damageCategoryCode, keyOptionList } from '../../constants'
import { getOptionsList } from '../../api/apis'
import * as Sentry from '@sentry/react'
import { v4 as uuidv4 } from 'uuid'
import { CategoryType } from './detailsListSelector'
import { availableLanguagesSelector } from './availableLanguagesSelector'

export const tireBrandListSelector = selector<CategoryType[]>({
  key: 'tireBrandListSelector',
  get: async({ get }) => {
    try {
      const keyValue = keyOptionList.CATEGORY
      const value = damageCategoryCode.TIRE_BRAND
      const optionList = await getOptionsList(
        keyValue,
        value,
        undefined,
        get(availableLanguagesSelector),
      ) ?? []
      return optionList.map(item => ({ id: uuidv4(), code: item.code, displayText: item.label }))
    } catch (err) {
      Sentry.captureException(err)
    }
  },
})