import { CIs } from '../constants/themeName'

const changeFavicon = (CI: string): void => {
  document.querySelector('#manifest').setAttribute('href', `/${CI ?? CIs.ARVAL}-manifest.json`)
  document.querySelector('#favicon').setAttribute('href', `/images/${CI ?? CIs.ARVAL}Logo/favicon.ico`)
  document.querySelector('#apple-touch-icon-180').setAttribute('href',
    `/images/${CI ?? CIs.ARVAL}Logo/logo-180x180.png`)
  document.querySelector('#shortcut-icon').setAttribute('href', `/images/${CI ?? CIs.ARVAL}Logo/favicon.ico`)
}

export default changeFavicon