export const FlowDescriptionKeys = {
  VEHICLE_DETAIL_STEP_DESCRIPTION_KEY: 'vehicle-details-header',
  ODO_METER_STEP_DESCRIPTION_KEY: 'odometer-reading-header',
  EXTERIOR_CHECKER_STEP_DESCRIPTION_KEY_CUSTOMER: 'video-checker-header',
  EXTERIOR_CHECKER_STEP_DESCRIPTION_KEY: 'photo-checker-header',
  CHECK_DAMAGES_STEP_DESCRIPTION_KEY: 'damage-overview-header',
  CHECK_TIRES_STEP_DESCRIPTION_KEY: 'tires-overview-header',
  ACCESSORY_STEP_DESCRIPTION_KEY: 'check-accessories-header',
  LOCATION_STEP_DESCRIPTION_KEY: 'location-header',
  SUMMARY_DESCRIPTION_KEY: 'summary',
  EXTERIOR_DESCRIPTION_KEY: 'exterior-checker-header',
  INTERIOR_DESCRIPTION_KEY: 'interior-checker-header',
}